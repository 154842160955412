import { PiArrowDown } from "react-icons/pi";
import PatternBackground from "./PatternBackground";
import Text from "./Text";

function Hero({ title, description }) {
    return (
        <PatternBackground>
            <div className="h-screen m-auto flex flex-row w-full md:max-w-screen-2xl lg:max-w-screen-2xl">
                <div className="flex flex-col justify-center text-center px-6 mg:px-32 lg:px-32 mx-auto">
                    <h1 className="font-archivo font-black mb-12 tracking-normal text-5xl md:text-8xl lg:text-8xl">
                        {title}
                    </h1>
                    <div>
                        <Text>{description}</Text>

                        <div className="flex justify-center items-center pt-12 md:pt-24 lg:pt-24">
                            <div className="animate-bounce">
                                <PiArrowDown color="#24c1ae" size={55} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PatternBackground>
    );
}

export default Hero;
