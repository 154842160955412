import pattern1 from "@/assets/bg-pattern-1.svg";
import pattern from "@/assets/bg-pattern.svg";

function PatternBackground({ patternImage = "primary", children }) {
    return (
        <div className="w-full relative">
            <div
                className="w-full bg-fixed"
                style={{
                    backgroundImage: `url(${
                        patternImage === "primary" ? pattern : pattern1
                    })`,
                }}
            >
                <div className="z-10 relative">{children}</div>
                <div className="z-0 w-full absolute bottom-0 bg-gradient-to-t from-black to-transparent h-44"></div>
                <div className="z-0 w-full absolute top-0 bg-gradient-to-b from-black to-transparent h-44"></div>
            </div>
        </div>
    );
}

export default PatternBackground;
